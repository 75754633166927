import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>82 Westbury Street, <br />Thornaby, TS17 6NA</p>

            <a href="tel:+441642507830" class="phone-link">01642 507 830</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>
                Wednesday - Monday<br /> 4pm – 11pm<br />
              </p>
          </div>
          <div class="col-md-8">
            <iframe
            class="map"
            title="Westbury Tandoori's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2313.839406828806!2d-1.3017427231937233!3d54.553956372664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e93e2f4291021%3A0xe27997a09508ce88!2sWestbury%20Tandoori!5e0!3m2!1sen!2suk!4v1721066702299!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;